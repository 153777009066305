import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// SysBiz
import { SysbizService } from './sysbiz.service';
import { SysbizConfig } from 'src/app/core/interfaces/sysbiz-config';
import { SysbizConfigService } from './sysbiz-config.service';
import { TranslateService } from '@ngx-translate/core';
import { FatturaClass } from 'src/app/features/invoices/components/invoice/fattura-elettronica.class.generated';
import { Option } from 'src/app/features/invoices/components/invoice/select/select.component';

@Injectable({
  providedIn: 'root'
})
export class SysbizSdiService extends SysbizService {

  /**
   * Type of API
   */
  override readonly api: string = 'sdi';

  private readonly _taxRates: string[] = ["0.00", "4.00", "10.00", "22.00"];

  private static readonly _formatiTrasmissione: Array<any> = ['FPA12', 'FPR12'];

  private static readonly _causaliPagamento: Array<any> = ['A', 'B', 'C', 'D', 'E', 'G', 'H', 'I', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'L1', 'M1', 'M2', 'O1', 'V1', 'ZO'];

  private static readonly _tipiScontoMaggiorazione: Array<any> = ['SC', 'MG'];
  
  private static readonly _art73: Array<any> = ['SI'];
  
  private static readonly _tipiCassa: Array<any> = ['TC01', 'TC02', 'TC03', 'TC04', 'TC05', 'TC06', 'TC07', 'TC08', 'TC09', 'TC10', 'TC11', 'TC12', 'TC13', 'TC14', 'TC15', 'TC16', 'TC17', 'TC18', 'TC19', 'TC20', 'TC21', 'TC22'];
  
  private static readonly _tipoDocumenti: Array<any> = ['TD01', 'TD02', 'TD03', 'TD04', 'TD05', 'TD06', 'TD16', 'TD17', 'TD18', 'TD19', 'TD20', 'TD21', 'TD22', 'TD23', 'TD24', 'TD25', 'TD26', 'TD27', 'TD28'];

  private static readonly _tipiRitenuta: Array<any> = ['RT01', 'RT02', 'RT03', 'RT04', 'RT05', 'RT06'];

  private static readonly _soggettiEmittente: Array<any> = ['CC', 'TZ'];

  private static readonly _regimiFiscali: Array<any> = ['RF01', 'RF02', 'RF03', 'RF04', 'RF05', 'RF06', 'RF07', 'RF08', 'RF09', 'RF10', 'RF11', 'RF12', 'RF13', 'RF14', 'RF15', 'RF16', 'RF17', 'RF19', 'RF18'];

  private static readonly _condizioniPagamento: Array<any> = ['TP01', 'TP02', 'TP03'];

  private static readonly _modalitaPagamento: Array<any> = ['MP01', 'MP02', 'MP03', 'MP04', 'MP05', 'MP06', 'MP07', 'MP08', 'MP09', 'MP10', 'MP11', 'MP12', 'MP13', 'MP14', 'MP15', 'MP16', 'MP17', 'MP18', 'MP19', 'MP20', 'MP21', 'MP22', 'MP23'];

  private static readonly _ritenute: Array<any> = ['SI'];

  private static readonly _esigibilitaIVA: Array<any> = ['D', 'I', 'S'];

  private static readonly _nature: Array<any> = ['N1', 'N2.1', 'N2.2', 'N3.1', 'N3.2', 'N3.3', 'N3.4', 'N3.5', 'N3.6', 'N4', 'N5', 'N6.1', 'N6.2', 'N6.3', 'N6.4', 'N6.5', 'N6.6', 'N6.7', 'N6.8', 'N6.9', 'N7'];

  private static readonly _sociUnici: Array<any> = ['SU', 'SM'];

  private static readonly _statiLiquidazione: Array<any> = ['LS', 'LN'];

  private static readonly _tipiCessionePrestazione: Array<any> = ['SC', 'PR', 'AB', 'AC'];

  private static readonly _bolliVirtuali: Array<any> = ['SI'];

  constructor(
    @Inject(SysbizConfigService) protected override config: SysbizConfig,
    protected http: HttpClient,
    private translationService: TranslateService
  ) {
    super(config);
  }

  public get TaxRates(): string[] {
    return this._taxRates;
  }

  public static get FormatiTrasmissione(): Array<any> {
    return this._formatiTrasmissione;
  }

  public get FormatiTrasmissioneTranslations(): Array<Option> {
    return this.getTranslationByType(SysbizSdiService.FormatiTrasmissione, "issuer");
  }

  public static get CausaliPagamento(): Array<any> {
    return this._causaliPagamento;
  }

  public get CausaliPagamentoTranslations(): Array<Option> {
    return this.getTranslationByType(SysbizSdiService.CausaliPagamento, "cause");
  }

  public static get TipiScontoMaggiorazione(): Array<any> {
    return this._tipiScontoMaggiorazione;
  }

  public get TipiScontoMaggiorazioneTranslations(): Array<Option> {
    return this.getTranslationByType(SysbizSdiService.TipiScontoMaggiorazione, "price-modification");
  }

  public static get Art73(): Array<any> {
    return this._art73;
  }

  public get Art73Translations(): Array<Option> {
    return this.getTranslationByType(SysbizSdiService.Art73, "art73");
  }

  public static get TipiCassa(): Array<any> {
    return this._tipiCassa;
  }

  public get TipiCassaTranslations(): Array<Option> {
    return this.getTranslationByType(SysbizSdiService.TipiCassa, "care");
  }

  public static get TipoDocumenti(): Array<any> {
    return this._tipoDocumenti;
  }

  public get TipoDocumentiTranslations(): Array<Option> {
    return this.getTranslationByType(SysbizSdiService.TipoDocumenti, "document");
  }

  public static get TipiRitenuta(): Array<any> {
    return this._tipiRitenuta;
  }

  public get TipiRitenutaTranslations(): Array<Option> {
    return this.getTranslationByType(SysbizSdiService.TipiRitenuta, "retention");
  }

  public static get SoggettiEmittente(): Array<any> {
    return this._soggettiEmittente;
  }

  public static get RegimiFiscali(): Array<any> {
    return this._regimiFiscali;
  }

  public get RegimiFiscaliTranslations(): Array<Option> {
    return this.getTranslationByType(SysbizSdiService.RegimiFiscali, "tax-regime");
  }

  public static get CondizioniPagamento(): Array<any> {
    return this._condizioniPagamento;
  }

  public static get ModalitaPagamento(): Array<any> {
    return this._modalitaPagamento;
  }

  public get ModalitaPagamentoTranslations(): Array<Option> {
    return this.getTranslationByType(SysbizSdiService.ModalitaPagamento, "payment");
  }

  public static get Ritenute(): Array<any> {
    return this._ritenute;
  }

  public get RitenuteTranslations(): Array<Option> {
    return this.getTranslationByType(SysbizSdiService.Ritenute, "ritenuta");
  }

  public static get EsigibilitaIVA(): Array<any> {
    return this._esigibilitaIVA;
  }

  public get EsigibilitaIVATranslations(): Array<Option> {
    return this.getTranslationByType(SysbizSdiService.EsigibilitaIVA, "vat");
  }

  public static get Nature(): Array<any> {
    return this._nature;
  }

  public get NatureTranslations(): Array<Option> {
    return this.getTranslationByType(SysbizSdiService.Nature, "nature");
  }

  public static get SociUnici(): Array<any> {
    return this._sociUnici;
  }

  public get SociUniciTranslations(): Array<Option> {
    return this.getTranslationByType(SysbizSdiService.SociUnici, "shareholder");
  }

  public static get StatiLiquidazione(): Array<any> {
    return this._statiLiquidazione;
  }

  public get StatiLiquidazioneTranslations(): Array<Option> {
    return this.getTranslationByType(SysbizSdiService.StatiLiquidazione, "liquidation");
  }

  public static get TipiCessionePrestazione(): Array<any> {
    return this._tipiCessionePrestazione;
  }

  public static get BolliVirtuali(): Array<any> {
    return this._bolliVirtuali;
  }

  public get BolliVirtualiTranslations(): Array<Option> {
    return this.getTranslationByType(SysbizSdiService.BolliVirtuali, "stamp");
  }

  /**
   * Get list of translations
   */
   public getTranslationByType(enumeration:  Array<any>, type: string, withKey: boolean = true): Array<Option> {
    let stati = new Array<Option>();
    enumeration.forEach((value) => {
      this.translationService.get(`${type}.types.${value}`).subscribe((res: string) => {
        stati.push({ value: value, description: (withKey ? value + ' - ' : '') + res });
      });
    });
    return stati;
  }
}
