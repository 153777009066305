import { Injectable } from '@angular/core';
import { LicensedModuleDto } from './customer-client/translation.service';
import { RestApiClientAdapterService } from '../sysbiz/rest-api-client-adapter.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, IDtoGuid, PagedResultDto } from '@systems/base';
import { CUSTOMERAPICLIENT_CONFIG } from '../sysbiz/config-loader.service';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContractService extends RestApiClientAdapterService<CompanyContract, CompanyContract, CompanyContract> {

  constructor(
    protected override http: HttpClient,
    authService: AuthService,
    protected translationService: TranslateService
  ) {
    super(CUSTOMERAPICLIENT_CONFIG, http, authService, 'contracts');
  }
  public listOpenContracts(): Observable<PagedResultDto<CompanyContract>> {

    let params = new HttpParams();
    
    return this.http.get<PagedResultDto<CompanyContract>>(this.getUrl(`companies/listOpenContracts`),
      {
        responseType: 'json',
        observe: 'response',
        headers: {
          authenticate: this.Token
        },
        params
      }).pipe(map((resp: any) => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }
}
export interface CompanyContract  extends IDtoGuid{
  licensedModule: LicensedModuleDto;
  companyName: string;
  contractGuid: string;
  contractName: string;
  state: string;
  signerLinkToken: string;
  createdUtc: Date;

}

export enum ContractSortingDto {
  name = 'Name',
}