import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { MonitoringService } from '@systems/base';
import { AuthService } from 'src/app/core/auth/auth.service';
import { DocTypeService, DocumentTypeDto } from 'src/app/core/sysbiz/conservation/doc-type.service';
import { NumberCompletenessCheckDto, NumberCompletenessCheckService, ServiceTypeDto, WriteNumberCompletenessCheckDto } from 'src/app/core/sysbiz/number-completeness-check.service';
import { BaseEditAdapterComponent } from 'src/app/shared/base-edit-adapter-component';
import { DocumentTypeHelper } from 'src/app/core/sysbiz/archive/document-type-helper.service';
import { Subscription } from 'rxjs';
import { CompanyService } from 'src/app/core/customer/customer-client/company.service';
import { SectionalDto } from 'src/app/core/customer/customer-client/company-details/sectional';


@Component({
  selector: 'app-edit-number-completeness',
  templateUrl: './edit-number-completeness.component.html',
  styleUrls: ['./edit-number-completeness.component.scss']
})
export class EditNumberCompletenessComponent extends BaseEditAdapterComponent<NumberCompletenessCheckDto, WriteNumberCompletenessCheckDto, NumberCompletenessCheckService> {

  public isMenuCollapsed: boolean;
  public docTypes: DocumentTypeDto[];
  protected sezionaleSubscription?: Subscription;
  public sezionale: Array<SectionalDto>;


  @Input() serviceType: ServiceTypeDto;

  get dtoName(): string {
    return "NumberCompletenessCheck"
  }
  protected generateDefault(): WriteNumberCompletenessCheckDto {
    return {
      name: "",
      companyGuid: "",
      docType: "FATATT",
      prefix: "",
      suffix: "",
      startNumber: undefined,
      endNumber: undefined,
      endDate: new Date,
      startDate: new Date,
      startRegisterNumber: undefined,
      endRegisterNumber: undefined,
      fillNumberWithZeros: false,
      sectional: "",
      serviceType: this.serviceType
    }
  }
  constructor(
    private docTypeService: DocTypeService,
    private companyService: CompanyService,
    public override service: NumberCompletenessCheckService,
    public documentTypeHelper: DocumentTypeHelper,
    modal: NgbModal,
    translationService: TranslateService,
    monitoringService: MonitoringService,
    public override authService: AuthService,
  ) {
    super(service, modal, monitoringService, translationService, authService);
  }

  override async ngOnInit() {
    super.ngOnInit();
    if (!this.item) {
      this.item = this.generateDefault();
    }
    if (this.item.serviceType === ServiceTypeDto.Postbox || this.serviceType === ServiceTypeDto.Postbox) {
      this.loadSectionals();
      this.item.docType = "FATATT";
    } else {
      this.docTypeService.listByFilters(false).subscribe((result) => {
        this.docTypes = result.results;
        this.docTypes = this.docTypes.slice().sort((a, b) => {
          const descriptionA = a.translations?.find(x => x.language == this.translationService.currentLang);
          const descriptionB = b.translations?.find(x => x.language == this.translationService.currentLang);
        
          const descA = descriptionA?.description ?? "";
          const descB = descriptionB?.description ?? "";
        
          return descA.localeCompare(descB);
        });
      });
    }
    this.item.companyGuid = this.authService.User.SelectedCompanyUid;
  }

  override get isValid() {
    if (!this.item.name) {
      return false;
    }

    if (!this.item.startDate) {
      return false;
    }
    if (!this.item.endDate) {
      return false;
    }

    if (this.hasRegisterNumberAndRegisterDate()) {
      if (!this.item.startRegisterNumber) {
        return false;
      }
      if (!this.item.endRegisterNumber) {
        return false;
      }
    }    
    else {
      if (!this.item.startNumber) {
        return false;
      }
  
      if (!this.item.endNumber) {
        return false;
      }
    }
    return true;
  }

  public override async save() {
    if (this.isValid) {
      if (this.hasRegisterNumberAndRegisterDate()) {
        if(this.item.startRegisterNumber)
          this.item.fillNumberWithZeros = this.item.startRegisterNumber.toString().startsWith("0");
      }
      else{
        if(this.item.startNumber)
          this.item.fillNumberWithZeros = this.item.startNumber.toString().startsWith("0");
      }
      await super.save();
    }
  }

  public loadSectionals() {
    this.sezionaleSubscription = this.companyService.get(this.authService.User.SelectedCompanyUid).subscribe({
      next: (x) => {
        this.sezionale = x.sectionals.filter(x => this.item.docType === undefined || x.docType === this.item.docType);
      },
      error: (x) => {
        this.raiseError(x.translatedMessage);
        this.sezionale = [];
        this.sezionaleSubscription = this.unsubscribeSubscription(this.sezionaleSubscription);
      },
      complete: () => {
        this.sezionaleSubscription = this.unsubscribeSubscription(this.sezionaleSubscription);
      }
    });
  }

  onDocTypeChange() {
    if (this.item.docType == 'DDTATT' || this.item.docType == 'DDTPAS') {
      this.item.startNumber = undefined;
      this.item.endNumber = undefined;
    }
    else {
      this.item.startRegisterNumber = undefined;
      this.item.endRegisterNumber = undefined;
    }
  }

  GetDocTypeTranslation(type: DocumentTypeDto): string | undefined {
    if (this.translationService.currentLang == "de" && type.translations)
      return type.translations.find(x => x.language == "de")?.description;
    else if (type.translations)
      return type.translations.find(x => x.language == "it")?.description;
    else
      return undefined
  }

  hasRegisterNumberAndRegisterDate():boolean{
    if(this.serviceType === 'Conservation' && ['DDTATT', 'DDTPAS', 'FATPAS', 'FATATT'].includes(this.item.docType)){
      return true
    }
    else
      return false
  }
}
