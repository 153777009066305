import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@systems/base';
import { map, Observable } from 'rxjs';
import { CUSTOMERAPICLIENT_CONFIG } from '../../sysbiz/config-loader.service';
import { RestApiClientAdapterService } from '../../sysbiz/rest-api-client-adapter.service';


@Injectable({
  providedIn: 'root'
})
export class TranslationService extends RestApiClientAdapterService<TranslationDto,TranslationDto,TranslationDto> {

  constructor(
    http: HttpClient,
    authService: AuthService
    ) {
    super(CUSTOMERAPICLIENT_CONFIG, http, authService, 'translations');
  }

  public listByFilters(type: TranslationEntityTypeDto,ids: Array<number> = null): Observable<any[]> {
    
    let params = new HttpParams();

    if(ids && ids.length > 0)
    {
      ids.forEach(id => {
        params = params.append('ids', id);
      });
    }

    return this.http.get<any[]>(this.getUrl(`translations/${type}`),
      {
        params: params,
        responseType: 'json',
        headers: {
          authenticate: this.Token
        },
        observe: "response"
      }).pipe(map((resp: any) => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }
}

export enum TranslationEntityTypeDto {
  State = "State",
  LicensedModule = "LicensedModule"
}

export interface TranslationDto {
  language: string;
  description: string;
}

export interface StateDto {
  id: number;
  translations: TranslationDto[];
  type?: string;
}

export interface LicensedModuleDto {
  id: number;
  translations: TranslationDto[];
}

export interface RegimeFiscaleDto {
  id: number;
  translations: TranslationDto[];
}
