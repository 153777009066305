import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from "lodash";

// SysBiz
import { SysbizService } from './sysbiz.service';
import { SysbizConfig } from 'src/app/core/interfaces/sysbiz-config';
import { SysbizConfigService } from './sysbiz-config.service';

export interface Country {
  code: string,
  name: string
}

export interface Province {
  code: string,
  name: string
}

export interface CompanyMeta {
  uid: string,
  name: string,
  physicalPerson: {
    title: string,
    name: string,
    surname: string
  },
  vat: {
    vat: string,
    state: string,
    taxNumber: string
  },
  address: {
    street: string,
    number?: number,
    cap: string,
    city: string,
    country: string,
    province: string
  },
  contact: {
    name: string,
    surname: string,
    mail: string,
    language: string
  },
  settings: {
    invoiceSettings: {
      contact: {
        fax: string,
        phone: string,
        mail: string
      },
      codeEORI: string,
      regimeFiscale: string,
      headQuarters: {
        street: string,
        number?: number,
        cap: string,
        city: string,
        country: string,
        province: string
      }
    },
    fiscalYearSettings: {
      fiscalYearDate: string,
      fiscalYearNext: number
    },
    sezionale: Array<{
      docType: number,
      docTypeName: string,
      name: string,
      description: string,
      conservation: boolean
    }>,
    conservationResponsible: Array<
      {
        person: string,
        startDate: string,
        additionalPersons: string
      }>,
    conservationWithoutProtocolInfos: boolean,
    rea: {
      office: string,
      number: string,
      soleMember: string,
      shareCapital: string,
      stateLiquidation: string
    },
    ritenuta: {
      cause: string,
      type: string,
      value: number
    },
    cassaPrevidenziale: {
      active: boolean,
      type: string,
      value: number
    },
    professionalRegister: {
      professionalRegister: string,
      province: string,
      number: string,
      registrationDate: string
    },
    payment: {
      modality: string,
      daysTerms: number,
      dueVat: string,
      bank: {
        name: string,
        iban: string,
        abi: string,
        cab: string,
        bic: string
      }
    },
    taxRepresentative: {
      company: string,
      vat: {
        vat: string,
        state: string,
        taxNumber: string
      },
      person: {
        title: string,
        name: string,
        surname: string
      },
      codeEORI: string
    }
  }
}

export interface Address {
  codiceDestinatario?: string,
  codeEORI?: string,
  pec?: string,
  name?: string,
  isReceiver?: boolean,
  physicalPerson?: {
    title: string,
    name: string,
    surname: string
  },
  vat: {
    vat: string,
    state: string,
    taxNumber: string
  },
  taxRepresentative?: {
    company: string,
    vat: {
      vat: string,
      state: string
    },
    person: {
      name: string,
      surname: string
    }
  },
  address: {
    street: string,
    number?: string,
    cap: string,
    city: string,
    country: string,
    province: string,
    taxSystem?: string,
  },
  headQuarters?: {
    street: string,
    number?: string,
    cap: string,
    city: string,
    country: string,
    province: string
  }
}


export interface CompanyDto {
  customer?: CustomerDto;
  uid?: string;
  vat?: string;
  taxnumber?: string;
  fullName?: string;
  companyName?: string;
  physicalPerson?: boolean;
  name?: string;
  surname?: string;
  fullVat?: string;
  code?: string;  
  conservationRelevantDocument?: string;
}


export interface CustomerDto {
  uid?: string;
  code?: string;
  isActive?: boolean;
  name?: string;
  vat?: string;
  taxnumber?: string;
  parent?: CustomerDto;
  launchDate?: Date;
  documentPackageSize?: number;
}


export interface NewsDto {
  id?: string;
  new?: boolean;
  type?: string;
  icon?: string;
  title?: string;
  content?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SysbizCustomerService extends SysbizService {
  [x: string]: any;

  /**
   * Type of API
   */
  override readonly api: string = 'customer';

  constructor(
    @Inject(SysbizConfigService) protected override config: SysbizConfig,
    protected http: HttpClient
  ) {
    super(config);
  }

  /**
   * Returns a list of companies for authenticated user
   * @param query Search query
   */
  public getCompanies = (query?: string, onlyActive: boolean = false, withCustomers: boolean = false, modules: string[] = []): Observable<Array<CompanyDto>> => {

    const queryParameters = new URLSearchParams();

    if (query !== null && query !== undefined) {
      queryParameters.set('searchQuery', <any>query);
    }

    if (modules != null && modules.length > 0) {
      modules.forEach(x => queryParameters.append('modules', x));
    }

    queryParameters.set('onlyActive', <any>onlyActive);
    queryParameters.set('withCustomers', <any>withCustomers);


    return this.http
      .get<Array<CompanyDto>>(
        this.url('companies?' + queryParameters),
        {
          responseType: 'json',
          headers: new HttpHeaders({ 'authenticate': this.Token })
        }
      );
  }
  public getCountCompany = (modules: string[] = []): Observable<number> => {
    const queryParameters = new URLSearchParams();
    
    if (modules != null && modules.length > 0) {
      modules.forEach(x => queryParameters.append('modules', x));
    }
    return this.http
      .get<number>(
        this.url('companies/count?' + queryParameters),
        {
          responseType: 'json',
          headers: new HttpHeaders({ 'authenticate': this.Token })
        }
      );
  }
    /**
   * Returns a list of companies for authenticated user
   * @param query Search query
   */
  public getCompany(uid: string, withCustomers: boolean = false): Observable<CompanyDto> {

    const queryParameters = new URLSearchParams();

    queryParameters.set('withCustomers', <any>withCustomers);

    return this.http
      .get<CompanyDto>(
        this.url('companies/' + uid + '?' + queryParameters),
        {
          responseType: 'json',
          headers: new HttpHeaders({ 'authenticate': this.Token })
        }
      );
  }

  /**
   * Get a list of addresses that corresponds to the query
   * @param query Search query
   * @param limti Limitation of the result
   */
  public getAddresses(query: string, limit: number = 0): Observable<Array<Address>> {

    const queryParameters = new URLSearchParams();

    if (query !== null && query !== undefined) {
      queryParameters.set('searchQuery', <any>query);
    }

    queryParameters.set('limit', <any>limit);

    return this.http
      .get<Array<Address>>(
        this.url('addresses?' + queryParameters),
        {
          responseType: 'json',
          headers: new HttpHeaders({ 'authenticate': this.Token })
        }
      )
      .pipe(
        map(
          (addresses: Array<Address>): Array<Address> => {
            const addressDefaults: Address = {
              codiceDestinatario: null,
              pec: null,
              name: null,
              physicalPerson: {
                title: null,
                name: null,
                surname: null
              },
              vat: {
                vat: null,
                state: null,
                taxNumber: null
              },
              taxRepresentative: {
                company: null,
                vat: {
                  vat: null,
                  state: null
                },
                person: {
                  name: null,
                  surname: null
                },
              },
              codeEORI: null,
              address: {
                street: null,
                cap: null,
                city: null,
                country: null,
                province: null,
                taxSystem: null
              },
              headQuarters: {
                street: null,
                number: null,
                cap: null,
                city: null,
                country: null,
                province: null
              }
            };

            // Fill empty pairs with defaults
            return addresses.map((address: Address): Address => _.defaultsDeep(address, addressDefaults));
          }
        )
      )
  }

  /**
   * Set a new address
   * @param address Address
   */
  public setAddress(address: Address) {
    return this.http
      .post(
        this.url('addresses'),
        JSON.stringify(cleanObject(address)),
        {
          headers: new HttpHeaders({
            'authenticate': this.Token,
            'Content-Type': 'application/json'
          })
        }
      );
  }

  /**
   * Get the current news
   * @param language language of the news
   */
  public getNews(language: string): Observable<NewsDto[]> {
    return this.http
      .get<NewsDto[]>(
        this.url('news?lang=' + language),
        {
          responseType: 'json',
          headers: new HttpHeaders({ 'authenticate': this.Token })
        }
      );
  }
}

/**
 * Remove empty objects
 * @param obj Object
 */
function cleanObject(obj: any): any {
  // Loop through properties
  for (const i in obj) {
    // Recursive call
    if (Object.prototype.toString.call(obj[i]) === '[object Object]') {
      obj[i] = cleanObject(obj[i]);
    }
  }

  // Remove empty properties
  obj = _.omitBy(obj, (val) => (_.isEmpty(val) || _.isNull(val) || _.isUndefined(val)) && !_.isBoolean(val));

  // Return 'clean' object
  return obj;
}
