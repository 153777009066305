import { Injectable } from '@angular/core';
import { CompanyDto } from './sysbiz-customer.service';
import { AuthService, PagedResultDto, SortDirectionDto } from '@systems/base';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { RestApiClientAdapterService } from './rest-api-client-adapter.service';
import { TranslationService } from '../customer/customer-client/translation.service';
import { CONSERVATIONAPICLIENT_CONFIG, CUSTOMERAPICLIENT_CONFIG } from './config-loader.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class NumberCompletenessCheckService extends RestApiClientAdapterService<
  NumberCompletenessCheckDto,
  DetailsNumberCompletenessCheckDto,
  WriteNumberCompletenessCheckDto
> {
  constructor(
    protected override http: HttpClient,
    authService: AuthService,
    protected translationService: TranslateService
  ) {
    super(CUSTOMERAPICLIENT_CONFIG, http, authService, 'numbercompletenesscheck');
  }
  override list(
    pageNumber?: number,
    pageSize?: number
  ): Observable<PagedResultDto<NumberCompletenessCheckDto>> {
    return null;
  }

  public listByFilters(
    sorting?: NumberCompletenessCheckSortingDto,
    sortDirection?: SortDirectionDto,
    searchName?: string,
    sectional?: string, 
    serviceType?: ServiceTypeDto,
    pageNumber?: number,
    pageSize?: number,
    companyGuid?: string
  ): Observable<PagedResultDto<NumberCompletenessCheckDto>> {
    let params = new HttpParams();
    if (sorting) {
      params = params.append("sorting", sorting as any);
    }
    if (sortDirection) {
      params = params.append("sortDirection", sortDirection as any);
    }
    if (pageNumber) {
      params = params.append("pageNumber", pageNumber as any);
    }
    if (pageSize) {
      params = params.append("pageSize", pageSize as any);
    }
    if (searchName) {
      params = params.append("searchName", searchName as any);
    }
    if (sectional) {
      params = params.append("sectional", sectional as any);
    }
    if (serviceType) {
      params = params.append("serviceType", serviceType as any);
    }
    if (companyGuid) {
      params = params.append("companyGuid", companyGuid as any);
    }

    return this.http
      .get<PagedResultDto<NumberCompletenessCheckDto>>(
        this.getUrl("numbercompletenesscheck"),
        {
          responseType: "json",
          headers: {
            'authenticate': this.Token,
          },
          observe: "response",
          params,
        }
      )
      .pipe(
        map((resp: any) => {
          this.updateTokenFromResponse(resp);
          return resp.body;
        })
      );
  }

  public override get(guid: string): Observable<DetailsNumberCompletenessCheckDto> {
    if (guid === null || guid === undefined) {
      throw new Error(
        "Required parameter numberCompletenessCheckGuid was null or undefined when calling GetNumberCompletenessCheck."
      );
    }

    return this.http
      .get<DetailsNumberCompletenessCheckDto>(
        this.getUrl("numbercompletenesscheck/" + guid),
        {
          headers: {
            'authenticate': this.Token,
          },
          observe: "response",
        }
      )
      .pipe(
        map((resp) => {
          this.updateTokenFromResponse(resp);
          return resp.body;
        })
      );
  }

  public override create(item: WriteNumberCompletenessCheckDto): Observable<DetailsNumberCompletenessCheckDto> {
    return this.http
      .post<NumberCompletenessCheckDto>(this.getUrl('numbercompletenesscheck'), item, {
        headers: {
          'authenticate': this.Token,
        },
        observe: "response",
        responseType: "json",
      })
      .pipe(
        map((resp) => {
          this.updateTokenFromResponse(resp);
          return resp.body!;
        })
      );
  }

  public override delete(guid: string): Observable<boolean> {
    return this.http
      .delete<boolean>(this.getUrl('numbercompletenesscheck/' + guid ), {
        headers: {
          'authenticate': this.Token,
        },
        observe: "response",
        responseType: "json"
      })
      .pipe(
        map((resp) => {
          this.updateTokenFromResponse(resp);
          return resp.body!;
        })
      );
  }

  public check(guid: string): Observable<NumberCompletenessCheckDto> {
    if (guid === null || guid === undefined) {
      throw new Error(
        "Required parameter numberCompletenessCheckGuid was null or undefined when calling GetNumberCompletenessCheck."
      );
    }

    return this.http
      .post<NumberCompletenessCheckDto>(
        this.getUrl("numbercompletenesscheck/" + guid + "/check"), null,
        {
          headers: {
            'authenticate': this.Token,
          },
          observe: "response",
          responseType: "json",
        })
        .pipe(
          map((resp) => {
            this.updateTokenFromResponse(resp);
            return resp.body!;
          })
        );
  }

  public listHistories(
    sorting?: NumberCompletenessCheckSortingDto,
    sortDirection?: SortDirectionDto,
    pageNumber?: number,
    pageSize?: number
  ): Observable<PagedResultDto<NumberCompletenessCheckDto>> {
    let params = new HttpParams();
    if (sorting) {
      params = params.append("sorting", sorting as any);
    }
    if (sortDirection) {
      params = params.append("sortDirection", sortDirection as any);
    }
    if (pageNumber) {
      params = params.append("pageNumber", pageNumber as any);
    }
    if (pageSize) {
      params = params.append("pageSize", pageSize as any);
    }

    return this.http
      .get<PagedResultDto<NumberCompletenessCheckDto>>(
        this.getUrl("numbercompletenesscheck/histories"),
        {
          responseType: "json",
          headers: {
            'authenticate': this.Token,
          },
          observe: "response",
          params,
        }
      )
      .pipe(
        map((resp: any) => {
          this.updateTokenFromResponse(resp);
          return resp.body;
        })
      );
  }
}

export interface NumberCompletenessCheckDto
  extends WriteNumberCompletenessCheckDto {
  guid: string;
  lastExecution?: NumberCompletenessCheckHistoryDto;
  toBeChecked: boolean;
}

export interface DetailsNumberCompletenessCheckDto
  extends NumberCompletenessCheckDto {}

export enum NumberCompletenessCheckSortingDto {
  name = "Name"
}

export interface NumberCompletenessCheckFilterDto {
  sorting?: NumberCompletenessCheckSortingDto;
  sortDirection?: SortDirectionDto;
  searchName?: string;
  pageNumber?: number;
  pageSize?: number;
}

export interface NumberCompletenessCheckHistoryDto {
  guid: string;
  executedUtc: Date;
  numberCompletenessCheckGuid: string;
  numberCompletenessCheck: NumberCompletenessCheckDto;
  missingDocumentNumbers: string[];
  missingDocumentCount: number;

}

 

export interface WriteNumberCompletenessCheckDto {
    name: string;
    companyGuid: string;
    docType: string;
    sectional: string;
    prefix?: string;
    suffix?: string;
    startNumber?: number;
    endNumber?: number;
    startRegisterNumber?: number;
    endRegisterNumber?: number;
    startDate?: Date;
    endDate?: Date;
    fillNumberWithZeros: boolean;
    serviceType: ServiceTypeDto;
}

 

export enum ServiceTypeDto {
    Postbox = "Postbox",
    Conservation = "Conservation"
}
