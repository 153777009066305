import { Observable, Subscription, interval } from 'rxjs';
import { share, startWith } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth';

export class ExceptionDescription {
  code?: number;

  defaultMessage?: string = "";

  messages?: Array<ErrorMessage>;

  validationErrors?: Array<ExceptionDescription>;

  constructor();
  constructor(source: any);
  constructor(source?: any) {
    if (source)
      Object.assign(this, source);
  }

  public getTranslatedMessage(language: string): string {

    if (!language || !this.messages)
      return this.defaultMessage;

    if (language.indexOf("-") > 0)
      language = language.substring(0, 2);

    for (let message of this.messages) {
      if (message.language?.toLowerCase() === language.toLowerCase() && message.message) {
        return message.message;
      }
    }

    return this.defaultMessage;
  }

}

export interface ErrorMessage {
  language?: string;

  message?: string;

}


export class Request {
  protected intervals: Array<any> = [];
  protected subscriptions: Array<any> = [];
  protected pending: boolean = false;

  /**
   * True if the request is pending requests
   */
  get isPending(): boolean {
    return this.pending;
  }

  public unsubscribeSubscriptions(): void {
    let subscription;
    while (subscription = this.subscriptions.shift())
      subscription.unsubscribe();
  }

  public unsubscribeIntervals() {
    let interval;
    while (interval = this.intervals.shift())
      interval.unsubscribe();
  }

  public unsubscribeAll(): void {
    // Unsubscribe subscriptions
    this.unsubscribeSubscriptions();

    // Unsubscribe intervals
    this.unsubscribeIntervals();

    // Set pending to false
    this.pending = false;
  }

}

export class ObservableRequest<T> extends Request {

  private observable$: Observable<T>;

  constructor(observable$: Observable<T>, private translate: TranslateService, private authService: AuthService) {
    super();
    this.observable$ = observable$.pipe(share());
  }

  /**
   * Return request
   */
  public get(): Observable<T> {
    return this.observable$;
  }

  public async toPromise(): Promise<T> {
    this.pending = true;

    return await this.observable$.toPromise()
      .then(x => {
        this.pending = false;
        return x;
      })
      .catch(err => {
        this.pending = false;
        throw this.parseErrorMessage(err);
      });
  }

  /**
   * Subscribe to a request
   * @param successCallback Callback for success
   * @param errorCallback Callback for error
   */
  public subscribe(successCallback: (res: T) => void, errorCallback?: (err: RequestError) => void): Subscription {
    this.pending = true;
    let subscription = this.observable$.subscribe(
      res => {
        this.pending = false;
        successCallback(res);
      },
      err => {
        const error = this.parseErrorMessage(err);

        // Execute callback if given
        if (errorCallback) {
          errorCallback(error);
        }
      }
    );
    this.subscriptions.push(subscription);
    return subscription;
  }

  public subscribeInterval(period: number, success: any, error?: any): Subscription {
    let intervalSubscription = interval(period)
      .pipe(startWith(0))
      .subscribe(
        () => {
          this.pending = true;
          let subscription = this.observable$.subscribe(
            (res) => {
              this.pending = false;
              success(res);
            },
            (err) => {
              this.pending = false;
              if (error)
                error(err);
            }
          )
          this.subscriptions.push(subscription);
        }
      );
    this.intervals.push(intervalSubscription)
    return intervalSubscription;
  }

  private parseErrorMessage(err: any): RequestError {
    let error: RequestError;

    this.pending = false;

    switch (err.status) {
      case 400:
        error = {
          severity: RequestErrorSeverity.ERROR,
          type: RequestErrorType.BADREQUEST
        };
        break;

      case 401:
        error = {
          severity: RequestErrorSeverity.ERROR,
          type: RequestErrorType.UNAUTHORIZED
        };
        break;

      case 403:
        error = {
          severity: RequestErrorSeverity.ERROR,
          type: RequestErrorType.FORBIDDEN
        };
        break;

      case 404:
        error = {
          severity: RequestErrorSeverity.ERROR,
          type: RequestErrorType.NOTFOUND
        };
        break;

      case 500:
        error = {
          severity: RequestErrorSeverity.FATAL,
          type: RequestErrorType.SERVER_ERROR
        };
        break;

      case 503:
      default:
        error = {
          severity: RequestErrorSeverity.FATAL,
          type: RequestErrorType.SERVICEUNAVAILABLE
        };
        break;
    }

    error.url = err.url;

    let jsonContent = err.error;

    if (jsonContent instanceof ArrayBuffer) {
      const decodedString = new TextDecoder().decode(jsonContent);
      jsonContent = JSON.parse(decodedString);
    }    

    if (jsonContent && jsonContent.code && jsonContent.defaultMessage) {
      const customException = new ExceptionDescription(jsonContent);

      // api error
      error.defaultMessage = customException.defaultMessage;
      error.errorCode = customException.code;
      error.translatedMessage = customException.getTranslatedMessage(this.translate.currentLang);

      if (err.error.defaultMessage === 'Token is expired') {
        // token is expired
        this.authService.logout();
        return error;
      }

    }
    else if (jsonContent && jsonContent.validationErrors) {
      const customException = new ExceptionDescription(jsonContent);
      error.defaultMessage = customException.defaultMessage;
      error.errorCode = customException.code;
      error.translatedMessage = customException.getTranslatedMessage(this.translate.currentLang);
      error.validationErrors = this.serializeValidationErrors(customException.validationErrors);
    }
    else {
      // Add error message
      if (error.type == RequestErrorType.UNAUTHORIZED) {
        this.authService.logout();
        return error;
      }
      error.defaultMessage = error.translatedMessage = err.message;
    }

    return error;
  }

  private serializeValidationErrors(validationErrors: Array<any>): Array<ExceptionDescription> {
    const internalValidationErrors = new Array<ExceptionDescription>();

    for (const validationError of validationErrors) {
      const validError = new ExceptionDescription();
      validError.code = validationError.exception ? validationError.exception.code : validationError.code;
      validError.defaultMessage = validationError.exception ? validationError.exception.defaultMessage : validationError.defaultMessage;
      validError.messages = validationError.exception ? validationError.exception.messages : validationError.messages;
      if (validationError.validationErrors) {
        validError.validationErrors = this.serializeValidationErrors(validationError.validationErrors);
      }

      internalValidationErrors.push(validError);
    }

    return internalValidationErrors;
  }
}


/**
 * Define the severity of a request error
 *
 * INFO: the INFO level designates informational messages that highlight the progress of the application at coarse-grained level.
 * WARN: the WARN level designates potentially harmful situations.
 * ERROR: the ERROR level designates error events that might still allow the application to continue running.
 * FATAL: the FATAL level designates very severe error events that will presumably lead the application to abort.
 */
export enum RequestErrorSeverity {
  INFO,
  WARNING,
  ERROR,
  FATAL
}

/**
 * Define the type of a request error
 */
export enum RequestErrorType {
  UNAUTHORIZED,
  SERVER_ERROR,
  FORBIDDEN,
  NOTFOUND,
  SERVICEUNAVAILABLE,
  BADREQUEST
}

/**
 * Interface of a request error
 */
export interface RequestError {
  errorCode?: number,
  type: RequestErrorType,
  severity: RequestErrorSeverity,
  defaultMessage?: string,
  translatedMessage?: string,
  url?: string,
  validationErrors?: Array<ExceptionDescription>
}
