import { HttpClient, HttpResponseBase } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthService, BaseApiClientService, RestClientConfig } from '@systems/base';
import { map, Observable } from 'rxjs';

export const TOKEN_NAME: string = 'token';
export const API_ENDPOINT_TOKEN = 'API_ENDPOINT_TOKEN';

@Injectable({
  providedIn: 'root'
})
export class RestApiClientAdapterService<Dto, DetailDto, WriteDto>
  extends BaseApiClientService<Dto, DetailDto, WriteDto>{

    private endpoint: string;

    constructor(
      configClient: RestClientConfig,
      http: HttpClient,
      protected override authService: AuthService,
      @Inject(API_ENDPOINT_TOKEN) apiEndpoint: string 
    ) {
      super(configClient, http, authService, apiEndpoint);

      this.endpoint = apiEndpoint;
    }


  /**
   * Get a single item
   *
   * @param guid id of the item
   */
  public override get(guid: string): Observable<DetailDto> {
    return this.http.get<DetailDto>(this.getUrl(this.endpoint + '/' + guid), {
      headers: {
        authenticate: this.Token
      },
      observe: 'response',
      responseType: 'json'
    })
      .pipe(map(resp => {
        this.updateTokenFromResponse(resp);
        return resp.body!;
      }));
  }

  public override create(item: WriteDto): Observable<DetailDto> {
    return this.http.post<DetailDto>(this.getUrl(this.endpoint), item, {
      headers: {
        'authenticate': this.Token
      },
      observe: 'response',
      responseType: 'json'
    })
      .pipe(map(resp => {
        this.updateTokenFromResponse(resp);
        return resp.body!;
      }));
  }

  public override update(guid: string, item: WriteDto): Observable<DetailDto> {

    return this.http.put<DetailDto>(this.getUrl(this.endpoint + '/' + guid), item, {
      headers: {
        'authenticate': this.Token
      },
      observe: 'response',
      responseType: 'json'
    })
      .pipe(map(resp => {
        this.updateTokenFromResponse(resp);
        return resp.body!;
      }))
  }



  /**
   * Get current user token
   */
   public get Token(): string {
    return localStorage.getItem(TOKEN_NAME);
  }

  /**
   *Update the token
   * @param newToken new token to use
   */
  public set Token(token: string) {
    if (token)
      localStorage.setItem(TOKEN_NAME, token);
    else
      localStorage.removeItem(TOKEN_NAME);
  }

  protected getToken(): string {
    return localStorage.getItem(TOKEN_NAME);
  }

  /**
   * Update the token from the http responce
   * @param response api response
   */
  protected override updateTokenFromResponse(response: HttpResponseBase) {
    let newToken = response.headers.get('authenticate');
    if (newToken)
      localStorage.setItem(TOKEN_NAME, newToken);
  }

  protected updateToken(newToken: string) {
    if (newToken)
      localStorage.setItem(TOKEN_NAME, newToken);
  }




  public override downloadFile(blob: Blob, fileName: string): Blob {
    if (!blob) {
      return null;
    }

    if (fileName.startsWith('\"') && fileName.endsWith('\"')) {
      fileName = fileName.slice(1, -1);
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);

    return blob;
  }

}
